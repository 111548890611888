<template>
  <div class="ask container">
    <h2 class="page-title">
      고객센터
      <div class="button">
        <b-button title="new" class="new" size="sm" @click.stop="init_new()">
          <font-awesome-icon icon="fa-solid fa-comment-dots" size="lg" />
          문의하기
        </b-button>
      </div>
    </h2>
    <p class="lead">
      캔디(CANDiY) 서비스는 어느 앱에서나 보관된 데이터를 다시 활용할 수 있게
      돕는 데이터 종합관리 서비스입니다.
    </p>
    <h3 class="faq">자주 묻는 질문</h3>
    <div class="accordion" role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-1
            >나의 데이터는 어디에 있나요?</b-button
          >
        </b-card-header>
        <b-collapse
          id="accordion-1"
          visible
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body>
            <!-- <b-card-text
              >I start opened because <code>visible</code> is
              <code>true</code></b-card-text
            > -->
            <b-card-text
              ><p>
                여러분의 개인 데이터는 여러 사이트/앱에 흩어져 보관되어
                있습니다. 여러분의 개인정보이지만 여러분이 관리하고 활용하기
                어려웠습니다. 만약 이사를 가게 되거나 휴대폰 번호를 바꾸게
                된다면 여러분이 직접 각 사이트에 방문해 개인정보를 변경해야
                했습니다.
              </p>
              <p>
                또한 여러분의 개인정보를 보관한 기업이 사라지기라도 한다면…?
                여러분의 소중한 데이터들은 삭제될 수도 있습니다.
              </p></b-card-text
            >
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-2
            >캔디 개인정보저장소(PDS)란 무엇인가요?</b-button
          >
        </b-card-header>
        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text
              ><p>
                캔디 개인정보저장소는 기업 중심이 아닌 여러분 스스로 데이터를
                통제/관리/활용할 수 있는 데이터 종합관리 기능을 보유한
                개인데이터 저장소입니다.
              </p>
              <ul>
                <li>
                  시간과 장소의 구애 없이 여러 곳에 흩어진 여러분의 데이터를
                  한꺼번에 모아 저장하고 한눈에 확인할 수 있는 기능을
                  포함합니다.
                </li>
                <li>
                  개인이 스스로 저장된 데이터를 관리할 수 있는 기능이
                  포함되었습니다. 보관 뿐 아니라 다른 기업에 나의 개인 데이터를
                  전달할 수 있는 통제권한 까지 누릴 수 있습니다.
                </li>
                <li>
                  보관된 개인 데이터를 다른 서비스에 전달할 수 있게 되고, 나만을
                  위한 서비스로 만들 수 있게 도와줍니다.
                </li>
              </ul></b-card-text
            >
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-3
            >캔디 서비스는 누가, 언제 쓰면 되나요?</b-button
          >
        </b-card-header>
        <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text
              ><ul>
                <li>
                  바쁜 일상을 살아가느라 앱, 웹사이트, 메일에서
                  이미지/동영상/문서 파일 다운로드 기간을 놓쳐 버리는 이들이,
                  <br />
                  → 캔디 저장소에서 시간에 공간의 구애없이 내가 원하는 데이터를
                  저장하고 확인 할 수 있습니다.
                </li>
                <li>
                  K메신저, L메신저, W메신저 등 개인이 쓰는 메신저에 흩어져 있는
                  데이터를 모으고 싶으나 어려운 이들이,<br />
                  → 캔디 저장소에서 각 메신저들의 데이터를 모아 안전하게
                  보관하고, K메신저에서 사용하던 정보를 L메신저에 옮겨서 사용할
                  수도 있습니다.
                </li>
                <li>
                  싸이월드, 야후 등 서비스가 사라질 때
                  개인정보(사진/동영상/메시지)등 중요한 개인정보를 잃어버린
                  경험이 있는 이들이 <br />
                  → 캔디 저장소에서는 서비스가 사라져도 개인 데이터를 안전하게
                  저장하고 재사용 가능 할 수 있도록 도와줍니다.
                </li>
              </ul></b-card-text
            >
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>

    <b-modal id="send-modal" title="문의하기" hide-footer centered>
      <div class="container flush">
        <!-- <FriendsSelection
          v-on:selected="onSelected"
          v-if="showFriends == true"
        /> -->
        <b-form @submit="onSubmit">
          <!-- init_new랑 send() 가지고 뭔가 수정해야할듯 -->
          <b-form-group id="input-group-1" label="성함 :" label-for="input-1">
            <b-form-input
              id="input-1"
              v-model="form.name"
              placeholder="성함을 입력하세요"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-2"
            label="이메일 주소:"
            label-for="input-2"
            description="답변을 받을 이메일을 입력하세요."
          >
            <b-form-input
              id="input-2"
              v-model="form.email"
              type="email"
              placeholder="이메일을 입력하세요"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-3"
            label="문의사항:"
            label-for="input-3"
          >
            <b-form-textarea
              id="input-3"
              v-model="form.content"
              placeholder="문의사항을 입력하세요"
              required
              rows="8"
            ></b-form-textarea>
          </b-form-group>
          <div class="button" style="text-align: right;">
            <b-button
              type="submit"
              style="background-color: #42b983;border: none;margin: 0;"
              >보내기</b-button
            >
          </div>
          <!-- <b-form-group>
            <label for="destinataire">WebId :</label>
            <b-form-input
              id="destinataire"
              v-model="recipient"
              placeholder="ex: https://spoggy-test.solidcommunity.net/profile/card#me"
            ></b-form-input>
            <b-button @click="add">Add</b-button> 
          </b-form-group>
          <b-input-group prepend="Label">
            <b-form-input v-model="label"></b-form-input>
          </b-input-group> -->
          <!--<b-list-group-item>Move</b-list-group-item>-->
          <!--<b-list-group-item><b-icon-trash @click="trash"></b-icon-trash></b-list-group-item>-->
          <!--  <b-list-group-item>Porta ac consectetur ac</b-list-group-item>
        <b-list-group-item>Vestibulum at eros</b-list-group-item>-->
        </b-form>
      </div>
    </b-modal>

    <!--    <p><a href="https://candiy.slack.com" target="_blank">Join CANDiY slack</a></p>-->

    <!--    <p>If you don't find answers to your questions about CANDiY, the best way is to use <a href="https://forum.solidproject.org/t/candiy-the-pod-in-the-pocket/3378" target="_blank">Solid Community Forum</a></p>-->
    <!--    <p>If you think CANDiY is what you need, you can share your opinion, impove & <a href="https://github.com/scenaristeur/solid-vue-panes/projects/1?add_cards_query=is%3Aopen" target="_blank">contribute</a> too.</p>-->

    <!--  <h3>Where is the source code of CANDiY?</h3>-->
    <!--    <p>Git repository at <a href="https://github.com/scenaristeur/solid-vue-panes/blob/master/README.md" target="_blank">https://github.com/scenaristeur/solid-vue-panes</a>.</p>-->

    <!-- <h3>Where are my data ?</h3>
    <p>
      With CANDiY, your data are stored on your
      <a href="https://solidproject.org/" target="_blank">Solid Pod</a>.
    </p>
    <h3>
      Why does CANDiY uses
      <a href="https://solidproject.org/" target="_blank"
        >Solid LinkedData Platform</a
      >
      as storage for my data ?
    </h3>
    <ol>
      <li>
        Because YOU are the one to choose where to store your data. On which POD
        provider.
      </li>
      <li>
        Because it uses LinkedData (<a
          href="https://www.w3.org/wiki/LinkedData"
          target="_blank"
          >w3c wiki</a
        >,
        <a href="https://www.w3.org/standards/semanticweb/data" target="_blank"
          >w3c standards</a
        >,
        <a
          href="https://fr.wikipedia.org/wiki/Web_des_donn%C3%A9es"
          target="_blank"
          >fr.wikipedia</a
        >).
      </li>
    </ol> -->

    <!--    <h3>Components</h3>-->
    <!--    CANDiY is developped around the concept of webcomponents.-->
    <!--    <ul>-->
    <!--      <li>SoligLogin (example of code with the source <a href="https://github.com/scenaristeur/solid-vue-panes/blob/master/src/components/solid/SolidLogin.vue" target="_blank">SolidLogin.vue</a> )</li>-->
    <!--    </ul>-->
    <!-- 
    <h3>Solid Libraries</h3>
    <ul>
      <li>
        <a
          href="https://github.com/solid/solid-auth-client"
          target="_blank"
          rel="noopener"
          >solid-auth-client</a
        >
      </li>
      <li>
        <a
          href="https://github.com/jeff-zucker/solid-file-client"
          target="_blank"
          rel="noopener"
          >solid-file-client</a
        >
      </li>
      <li>
        <a
          href="https://github.com/solid/query-ldflex"
          target="_blank"
          rel="noopener"
          >ldflex-query</a
        >
        (<a
          href="https://comunica.github.io/Tutorial-ISWC2019-Slides-LDflex/#"
          target="_blank"
          rel="noopener"
          >ldflex-query tuto</a
        >)
      </li>
      <li>
        <a
          href="https://vincenttunru.gitlab.io/tripledoc/"
          target="_blank"
          rel="noopener"
          >tripledoc</a
        >
      </li>
      <li>
        <a
          href="https://docs.inrupt.com/developer-tools/javascript/client-libraries/"
          target="_blank"
          rel="noopener"
          >@inrupt/solid-client</a
        >
      </li>
    </ul>
    <h3>Installed CLI Plugins</h3>
    <ul>
      <li>
        <a
          href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-babel"
          target="_blank"
          rel="noopener"
          >babel</a
        >
      </li>
      <li>
        <a
          href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-pwa"
          target="_blank"
          rel="noopener"
          >pwa</a
        >
      </li>
      <li>
        <a
          href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-router"
          target="_blank"
          rel="noopener"
          >router</a
        >
      </li>
      <li>
        <a
          href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-vuex"
          target="_blank"
          rel="noopener"
          >vuex</a
        >
      </li>
      <li>
        <a
          href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-eslint"
          target="_blank"
          rel="noopener"
          >eslint</a
        >
      </li>
      <li>
        <a
          href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-unit-mocha"
          target="_blank"
          rel="noopener"
          >unit-mocha</a
        >
      </li>
    </ul>
    <h3>Essential Links</h3>
    <ul>
      <li>
        <a href="https://vuejs.org" target="_blank" rel="noopener">Core Docs</a>
      </li>
      <li>
        <a href="https://forum.vuejs.org" target="_blank" rel="noopener"
          >Forum</a
        >
      </li>
      <li>
        <a href="https://chat.vuejs.org" target="_blank" rel="noopener"
          >Community Chat</a
        >
      </li>
      <li>
        <a href="https://twitter.com/vuejs" target="_blank" rel="noopener"
          >Twitter</a
        >
      </li>
      <li>
        <a href="https://news.vuejs.org" target="_blank" rel="noopener">News</a>
      </li>
    </ul>
    <h3>Ecosystem</h3>
    <ul>
      <li>
        <a href="https://router.vuejs.org" target="_blank" rel="noopener"
          >vue-router</a
        >
      </li>
      <li>
        <a href="https://vuex.vuejs.org" target="_blank" rel="noopener">vuex</a>
      </li>
      <li>
        <a
          href="https://github.com/vuejs/vue-devtools#vue-devtools"
          target="_blank"
          rel="noopener"
          >vue-devtools</a
        >
      </li>
      <li>
        <a href="https://vue-loader.vuejs.org" target="_blank" rel="noopener"
          >vue-loader</a
        >
      </li>
      <li>
        <a
          href="https://github.com/vuejs/awesome-vue"
          target="_blank"
          rel="noopener"
          >awesome-vue</a
        >
      </li>
    </ul> -->
  </div>
</template>
<script>
import loginMixin from "@/mixins/loginMixin";
import profileMixin from "@/mixins/profileMixin";
import aclMixin from "@/mixins/aclMixin";
import auth from "solid-auth-client";
import FC from "solid-file-client";
const fc = new FC(auth);
//import { deleteFile } from "@inrupt/solid-client";
import { fetchDocument, createDocument } from "tripledoc";
import { schema, space } from "rdf-namespaces";
//const { namedNode } = require('@rdfjs/data-model');

export default {
  name: "Inbox",
  mixins: [loginMixin, profileMixin, aclMixin],
  components: {},
  props: ["value"],
  data: function() {
    return {
      form: {
        email: "",
        name: "",
        content: "",
      },
    };
  },
  async created() {
    // email send template https://github.com/lethaldose/email-client
    this.webId = this.$store.state.solid.webId;
    console.log("################# created inbox webid", this.webId);
    if (this.webId != null) {
      this.inbox_urls = await this.getInboxUrls(this.webId);
      this.storage = this.$store.state.solid.storage;
      await this.inbox_init("created webId non null");
      await this.configureInbox(this.inbox_urls[0], this.webId, this.storage);
      this.inbox_log_file = this.storage + "candiy/inbox_log.ttl";
      console.log("created inbox_log_file", this.inbox_log_file);
      this.subscribe();
      /*this.inbox_log_file = this.storage+"candiy/inbox_log.ttl"
      console.log("CREATED inbox_log_file",this.inbox_log_file)
      this.subscribe()*/
    } else {
      await this.inbox_init("created webId null");
      this.popupLogin();
    }

    //  this.webId = this.$route.params.webId || this.$store.state.solid.webId
    //  this.updateFriends()
  },

  watch: {
    async webId(webId) {
      await this.inbox_init("webid watch");
      console.log("############# WEBID changed", webId);
      if (webId != null) {
        this.inbox_urls = await this.getInboxUrls(webId);
      }
    },
    async inbox_urls(i_u) {
      this.current_inbox_url = i_u[0];
      if (this.current_inbox_url != null) {
        console.log(this.current_inbox_url);
        this.getMessages();
      }
    },
    reply(r) {
      console.log("reply", r);
      this.showFriends = false;
      this.$bvModal.show("send-modal");
      this.selected = [];
      this.recipient = r.sender;
      this.label = "ref: " + r.label;
    },
    storage(st) {
      if (st != null) {
        this.inbox_log_file = st + "candiy/inbox_log.ttl";
        console.log("STORAGE WATCH inbox_log_file", this.inbox_log_file);
        this.subscribe();
      }
    },
  },
  methods: {
    async inbox_init(parent) {
      console.log(
        "###################################### INBOX INIT from ",
        parent
      );
      console.log("INIT WITH", this.webId, this.inbox_urls[0], this.storage);
    },
    notify(message = "This is an example!") {
      // https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification#Parameters
      this.$notification.show(
        "Hello World",
        {
          body: message,
        },
        {}
      );
    },
    send() {
      console.log(
        this.title,
        this.content,
        this.selected,
        this.label,
        this.recipient
      );
      if (this.recipient != null) {
        this.selected.push(this.recipient);
      }

      console.log("selected", this.selected);
      if (this.content.length > 0) {
        let message = {};
        //
        var dateObj = new Date();
        message.date = dateObj.toISOString();
        message.id = dateObj.getTime();
        message.sender = this.webId;

        message.content = this.content;
        message.label = this.label;

        let messageStr = `
        @prefix : <#>.
        @prefix schem: <http://schema.org/>.
        @prefix sch: <https://schema.org/>.
        @prefix rd: <http://www.w3.org/2000/01/rdf-schema#>.

        <>
        a sch:Message;
        schem:dateSent "${message.date}";
        schem:sender <${message.sender}>;
        schem:text "${message.content}";
        rd:label "${message.label}";
        schem:abstract "this message can be opened with inbox of CANDiY https://scenaristeur.github.io/solid-vue-panes/".
        `;
        console.log(messageStr);
        /*
        if (this.r_inbox!= undefined && this.r_inbox.length > 0){
        this.selected.push( this.r_inbox[0])
      }*/

        let getInboxUrls = this.getInboxUrls;

        console.log(this.selected);
        this.selected.forEach(async function(webId) {
          let inbox_urls = await getInboxUrls(webId);
          if (inbox_urls != undefined && inbox_urls.length > 0) {
            console.log("INBOX", inbox_urls);
            message.url = inbox_urls[0] + message.id + ".ttl";
            //  let notif = inbox_log_file+"#"+message.id
            console.log(message.url);

            await fc.postFile(message.url, messageStr, "text/turtle");
            //  await solid.data[notif].schema$about.add(namedNode(message.url))

            /*    let recipient_storage = await solid.data[webId].storage
          let inbox_log_file = recipient_storage+"candiy/inbox_log.ttl"
          console.log(inbox_log_file)*/
            console.log("find storage of ", webId);
            //  let recipient_storage = await solid.data[webId].storage

            const recipientDoc = await fetchDocument(webId);
            const rec = await recipientDoc.getSubject(webId);
            const recipient_storage = await rec.getNodeRef(space.storage);

            console.log(`${recipient_storage}`);
            let recipient_log_file =
              `${recipient_storage}` + "candiy/inbox_log.ttl";
            console.log(recipient_log_file);
            let logDoc = {};
            try {
              logDoc = await fetchDocument(recipient_log_file);
            } catch (e) {
              logDoc = await createDocument(recipient_log_file);
            }

            let s = logDoc.addSubject();
            s.addNodeRef(schema.about, message.url);
            //  console.log(logDoc)
            await logDoc.save();
          }
        });
      } else {
        alert("content must not be empty");
      }
    },
    onSelected: function(selected) {
      this.selected = selected;
      console.log(this.selected);
    },
    init_new() {
      console.log("new");
      //  this.new = true
      this.showFriends = true;
      this.$bvModal.show("send-modal");
      this.selected = [];
      this.recipient = null;
      this.label = "";
      this.content = "";
    },
    async subscribe() {
      var websocket = "wss://" + this.inbox_log_file.split("/")[2];
      let socket = new WebSocket(websocket, ["solid.0.1.0"]);
      let inbox_log_file = this.inbox_log_file;
      socket.onopen = function() {
        //      var now = d.toLocaleTimeString(app.lang)
        this.send("sub " + inbox_log_file);
        console.log("subscribe to INBOX", websocket, inbox_log_file);
        //  app.agent.send('Messages',  {action:"info", info: now+"[souscription] "+url});
      };

      let getMessages = this.getMessages;
      socket.onmessage = function(msg) {
        console.log(msg);
        if (msg.data && msg.data.slice(0, 3) === "pub") {
          //  app.notification("nouveau message Socialid")
          //app.openLongChat()
          console.log(msg.data);
          //  notification("new inbox message")
          getMessages();

          //app.todayMessages()
          //  app.agent.send("Flux", {action: "websocketMessage", url : url})
        }
      };
    },
    async getMessages() {
      this.inbox = await fc.readFolder(this.current_inbox_url);
      this.notify(this.inbox.files.length + " messages !!!");
      this.$store.commit("inbox/setInbox", this.inbox);
    },
    async trash() {
      console.log(this.toTrash);
      //  await fc.deleteFile( this.toTrash, {withAcl:false})

      await fc.deleteFile(this.toTrash);
      console.log("File deleted !");
      const logDoc = await fetchDocument(this.inbox_log_file);
      let s = logDoc.findSubject(schema.about, this.toTrash);
      logDoc.removeSubject(s);
      //s.addNodeRef(schema.about, message.url)
      //  console.log(logDoc)
      await logDoc.save();
    },
    onSubmit(event) {
      event.preventDefault();
      // alert(JSON.stringify(this.form));
    },
  },
  computed: {
    webId: {
      get: function() {
        return this.$store.state.solid.webId;
      },
      set: function() {},
    },
    toTrash() {
      return this.$store.state.inbox.toTrash;
    },
    reply() {
      return this.$store.state.inbox.reply;
    },
    storage: {
      get: function() {
        return this.$store.state.solid.storage;
      },
      set: function() {},
    },
    link() {
      console.log("route", this.$route, location.href, location.host);
      let path =
        "/friends?add=https://spoggy.solidcommunity.net/profile/card#me";
      return path; //'<a href="'+path+'" target="_blank">Be my friend</a>'
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ask {
  text-align: left;
  margin-top: 20px;
  width: 90%;
  padding: 0;
  margin-bottom: 50px;
  position: relative;
}
h2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ask h2 .button {
  margin-left: 20px;
  min-width: 100px;
  width: 50px;
}
.ask h2 .button button {
  background-color: #5ebdeb;
  border: none;
  border-radius: 20px;
  width: 100%;
  height: 35px;
  box-shadow: 3px 3px 15px rgba(94, 189, 235, 0.2);
  font-size: 14px;
}
a {
  color: #42b983;
}
.page-title {
  font-size: 26px;
  font-weight: 600;
  color: #333;
}
.lead {
  margin: 20px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  color: #666;
  word-break: keep-all;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
h3 {
  font-size: 20px;
  font-weight: 600;
}
.inbox {
  text-align: left;
}

.card {
  border-radius: 0px;
  border: none;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.13);
}
.card-header {
  background-color: #fff;
  border-bottom: none;
}
.card-header button {
  background: #fb5c5c;
  border: none;
  font-weight: 600;
  font-size: 16px;
  word-break: keep-all;
}
.card-body {
  padding: 1.5rem 1.25rem 1rem;
}
.card-body .card-text p {
  word-break: keep-all;
  font-size: 15px;
}
.card-body .card-text ul {
  padding: 0 1.25rem;
  list-style: decimal;
  font-size: 15px;
}
.card-body .card-text ul li {
  margin-bottom: 10px;
}
</style>
